export const regions = [
  {
    region: 'Eastern Cape',
    rsmEmail: 'JapieE@BidvestLife.co.za',
    rsmName: 'Japie Erasmus',
  },
  {
    region: 'Free State',
    rsmEmail: 'JapieE@BidvestLife.co.za',
    rsmName: 'Japie Erasmus',
  },
  {
    region: 'Gauteng (Pretoria)',
    rsmEmail: 'FrancoisB@BidvestLife.co.za',
    rsmName: 'Francois Botha',
  },
  {
    region: 'Gauteng (Johannesburg)',
    rsmEmail: 'KhayaT@BidvestLife.co.za',
    rsmName: 'Khaya Tuku',
  },
  {
    region: 'KwaZulu-Natal',
    rsmEmail: 'MarietteN@BidvestLife.co.za',
    rsmName: 'Mariette Nel',
  },
  {
    region: 'Limpopo',
    rsmEmail: 'FrancoisB@BidvestLife.co.za',
    rsmName: 'Francois Botha',
  },
  {
    region: 'Mpumalanga',
    rsmEmail: 'FrancoisB@BidvestLife.co.za',
    rsmName: 'Francois Botha',
  },
  {
    region: 'North West',
    rsmEmail: 'JapieE@BidvestLife.co.za',
    rsmName: 'Japie Erasmus',
  },
  {
    region: 'Northern Cape',
    rsmEmail: 'JapieE@BidvestLife.co.za',
    rsmName: 'Japie Erasmus',
  },
  {
    region: 'Western Cape',
    rsmEmail: 'MarkN@BidvestLife.co.za',
    rsmName: 'Mark Neil',
  },
]
