import React, {useEffect} from 'react'
import {Header, Hero, Form, Footer} from './components'
import styled, {createGlobalStyle} from 'styled-components'
import {ThemeProvider} from '@fmi/design-system'
import * as Sentry from '@sentry/browser'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background: #f3f3f4;
  }
`

function App() {
  useEffect(() => {
    Sentry.init({
      dsn:
        'https://0c38b8a063f44c8cad1cc6de17a6355e@o391039.ingest.sentry.io/5236514',
    })
    LogRocket.init('bxwntl/get-contacted-form')
    setupLogRocketReact(LogRocket)
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL)
      })
    })
  }, [])

  return (
    <React.Fragment>
      <GlobalStyle />
      <ThemeProvider>
        <Layout>
          <Header />
          <Hero />
          <Form></Form>
          <Footer />
        </Layout>
      </ThemeProvider>
    </React.Fragment>
  )
}

export default App

const Layout = styled.main``
