import axios from 'axios'
import * as Sentry from '@sentry/browser'
import { regions } from './regions'

async function sendMail(values) {
  // map region to RSM
  const RSM = regions.find(x => x.region === values.region)

  // SendGrid request
  const body = {
    mailRequest: {
      ToEmailAddressList: [
        {
          Email: RSM.rsmEmail,
          Name: RSM.rsmName,
        },
      ],
      ToCCEmailAddressList: [
        {
          Email: 'leads@BidvestLife.co.za',
          Name: 'Bidvest Life Leads'
        }
      ],
      Template: 'GetCovered',
      TemplateData: {
        date: new Date().toLocaleString(),
        title: values.cf_title,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        age: values.age.toString(),
        region: values.region,
        emailAddress: values.emailAddress,
        phoneNumber: values.phoneNumber.toString(),
        occupation: values.occupation,
        language: values.language,
      },
      Categories: ['Get Contacted Form'],
    },
  }

  await axios
    .post(
      'https://website-gatekeeper.fmi.co.za/api/v1.0/contact',
      body,
    )
    .then(() => true)
    .catch(e => {
      Sentry.setUser({email: values.emailAddress})
      Sentry.captureException(e)
      return false
    })
}

export {sendMail}
