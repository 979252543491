import React from 'react'
import styled from 'styled-components'
import {Text} from '@fmi/design-system'

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <CopyText>All rights reserved. © 2022 BidvestLife.co.za.</CopyText>
        <CopyText>
          A licensed Life Insurance company and authorised Financial Services
          Provider FSP 47801
        </CopyText>
      </div>
      <div>
        <Link>
          <a
            href="https://www.fmi.co.za/terms-and-conditions"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Ts & Cs
          </a>
        </Link>
      </div>
    </StyledFooter>
  )
}

export {Footer}

const StyledFooter = styled.footer`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 816px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 140px;
  padding-top: 43px;
  padding-bottom: 34px;
  border-top: solid 1px #989ca8;

  @media ${props => props.theme.BREAKPOINTS.TABLET_DOWN} {
    padding: 32px 16px;
    flex-direction: column;
    text-align: center;
    margin-top: 0;
    border-top: 0 none;

    & > div {
      width: 100%;
    }
  }
`

const CopyText = styled(Text)`
  font-size: ${props => props.theme.fontSize[13]};
  color: #5f6976;
  opacity: 0.5;
  margin: 0;
`

const Link = styled(Text)`
  margin: 0;

  a {
    font-size: ${props => props.theme.fontSize[13]};
    color: #5f6976;
    text-decoration: none;
    transition: all 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
`
