import React from 'react'
import styled from 'styled-components'
import {Heading, Text} from '@fmi/design-system'

const Hero = () => {
  return (
    <StyledHero>
      <Overlay />
      <CopyContainer>
        <StyledHeading color="white">We’re for what you do</StyledHeading>
        <StyledSubHeading color="white">Let's get you covered</StyledSubHeading>
        <StyledText color="white">
          Get in touch for a no-strings attached chat! Simply enter your details
          below and a trusted, independent financial adviser will be in touch.
        </StyledText>
      </CopyContainer>
    </StyledHero>
  )
}

export {Hero}

const StyledHero = styled.section`
  display: flex;
  background: url('./images/header_background.jpg') no-repeat center 15%;
  background-size: cover;
  flex: 1;
  height: 455px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0.85;
  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    background-position: 80% 15%;
  }
`

const Overlay = styled.div`
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  opacity: 0.3;
  height: 455px;
`

const CopyContainer = styled.div`
  max-width: 816px;
  z-index: 1;
  text-align: center;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    padding: 0 16px;
  }
`

const StyledHeading = styled(Heading)`
  font-size: 55px;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    font-size: 28px;
    margin-bottom: 0;
  }
`
const StyledSubHeading = styled(Heading)`
  font-size: 36px;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    font-size: 24px;
    margin-bottom: 0;
  }
`

const StyledText = styled(Text)`
  font-size: 18px;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    font-size: 15px;
    margin-top: 0;
  }
`
