const validate = values => {
  const errors = {}
  if (!values.cf_title) {
    errors.cf_title = 'Title is required'
  }
  if (!values.firstName) {
    errors.firstName = 'First name is required'
  }
  if (!values.lastName) {
    errors.lastName = 'Last name is required'
  }
  if (!values.gender) {
    errors.gender = 'Gender is required'
  }
  if (!values.age) {
    errors.age = 'Age is required'
  }
  if (!values.region) {
    errors.region = 'Region is required'
  }
  if (!values.emailAddress) {
    errors.emailAddress = 'Email address is required'
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)
  ) {
    errors.emailAddress = 'Invalid email address'
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Phone number is required'
  }
  if (!values.occupation) {
    errors.occupation = 'Occupation is required'
  }
  if (!values.language) {
    errors.language = 'Language is required'
  }
  return errors
}

export {validate}
