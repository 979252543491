import React from 'react'
import styled from 'styled-components'
import {Modal, Heading, Text, PrimaryButton} from '@fmi/design-system'

const ModalWrapper = ({open}) => {
  return (
    <Modal isOpen={open}>
      <ModalContent>
        <Heading level={3}>Thanks for reaching out.</Heading>
        <Text>A trusted independent financial adviser will be in touch.</Text>
        <PrimaryButton
          onClick={() => (window.location.href = 'https://www.BidvestLife.co.za')}
        >
          Back
        </PrimaryButton>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`

export {ModalWrapper as Modal}
