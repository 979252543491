import React from 'react'
import styled from 'styled-components'
import {Icon, theme, Text} from '@fmi/design-system'

const Header = () => {
  return (
    <StyledHeader>
      <a href="https://www.BidvestLife.co.za">
        <Image src="/images/logo-white.png" alt="Bidvest Life Logo" />
      </a>
      <CTA href="tel:+27860101119">
        <Icon icon="MdPhone" size="22px" color={theme.colors.red} />
        <PhoneText>086 010 1119</PhoneText>
      </CTA>
    </StyledHeader>
  )
}

export {Header}

const StyledHeader = styled.header`
  position: absolute;
  z-index: 1;
  padding: 16px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    padding: 16px;
    border-bottom: solid 1px rgba(255, 255, 255, 0.35);
  }
`

const Image = styled.img`
  height: 70px;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    height: 50px;
  }
`

const CTA = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`

const PhoneText = styled(Text)`
  color: ${theme.colors.white};
  margin-left: ${theme.gutters[16]};
  font-size: 15px;
`
