import React, {useState} from 'react'
import styled from 'styled-components'
import {useFormik} from 'formik'
import {
  Select,
  TextInput,
  SegmentedControl,
  PrimaryButton,
} from '@fmi/design-system'
import {Modal} from '../components'
import {sendMail, validate, regions} from '../helpers'

const Form = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      cf_title: '',
      firstName: '',
      lastName: '',
      gender: '',
      age: '',
      region: '',
      emailAddress: '',
      phoneNumber: '',
      occupation: '',
      language: '',
    },
    validate,
    onSubmit: async values => {
      setIsLoading(true)
      await sendMail(values)
      setIsLoading(false)
      setIsModalOpen(true)
    },
  })

  const mappedRegions = regions.map(region => {
    return {
      label: region.region,
      value: region.region
    }
  })

  return (
    <Container>
      <form onSubmit={formik.handleSubmit}>
        <Select
          options={[
            {label: 'Mr', value: 'Mr'},
            {label: 'Mrs', value: 'Mrs'},
            {label: 'Ms', value: 'Ms'},
            {label: 'Miss', value: 'Miss'},
            {label: 'Dr', value: 'Dr'},
            {label: 'Prof', value: 'Prof'},
            {label: 'Sir', value: 'Sir'},
            {label: 'Honorable', value: 'Honorable'},
            {label: 'Judge', value: 'Judge'},
            {label: 'Rev', value: 'Rev'},
            {label: 'Rabbi', value: 'Rabbi'},
            {label: 'Pastor', value: 'Pastor'},
            {label: 'Brother', value: 'Brother'},
            {label: 'Sister', value: 'Sister'},
            {label: 'Father', value: 'Father'},
            {label: 'Ds', value: 'Ds'},
            {label: 'Captain', value: 'Captain'},
            {label: 'Adv', value: 'Adv'},
          ]}
          label="Title"
          placeholder="Select your title"
          onChange={({value}) => formik.setFieldValue('cf_title', value.value)}
          id="cf_title"
          name="cf_title"
          error={!!(formik.errors.cf_title && formik.touched.cf_title)}
          errorMessage={formik.errors.cf_title}
        />
        <TextInput
          label="First Name"
          onChange={formik.handleChange}
          id="first_name"
          name="firstName"
          error={!!(formik.errors.firstName && formik.touched.firstName)}
          errorMessage={formik.errors.firstName}
        />
        <TextInput
          label="Last Name"
          onChange={formik.handleChange}
          id="last_name"
          name="lastName"
          error={!!(formik.errors.lastName && formik.touched.lastName)}
          errorMessage={formik.errors.lastName}
        />
        <div>
          <SegmentedControl
            defaultOption={'Male'}
            segments={['Male', 'Female']}
            label="Gender"
            onChange={formik.handleChange}
            id="cf_gender"
            name="gender"
            error={!!(formik.errors.gender && formik.touched.gender)}
            errorMessage={formik.errors.gender}
          />
        </div>
        <TextInput
          label="Age"
          fieldType="number"
          onChange={formik.handleChange}
          id="cf_age"
          name="age"
          error={!!(formik.errors.age && formik.touched.age)}
          errorMessage={formik.errors.age}
        />
        <Select
          label="Region"
          options={mappedRegions}
          placeholder="Select your region"
          onChange={({value}) => formik.setFieldValue('region', value.value)}
          id="cf_region"
          name="region"
          error={!!(formik.errors.region && formik.touched.region)}
          errorMessage={formik.errors.region}
        />
        <TextInput
          label="Email Address"
          onChange={formik.handleChange}
          id="cf_email_address"
          name="emailAddress"
          error={!!(formik.errors.emailAddress && formik.touched.emailAddress)}
          errorMessage={formik.errors.emailAddress}
        />
        <TextInput
          label="Phone Number"
          fieldType="number"
          onChange={formik.handleChange}
          id="cf_phone_number"
          name="phoneNumber"
          error={!!(formik.errors.phoneNumber && formik.touched.phoneNumber)}
          errorMessage={formik.errors.phoneNumber}
        />
        <TextInput
          label="Occupation"
          onChange={formik.handleChange}
          id="cf_occupation"
          name="occupation"
          error={!!(formik.errors.occupation && formik.touched.occupation)}
          errorMessage={formik.errors.occupation}
        />
        <TextInput
          label="Language"
          onChange={formik.handleChange}
          id="cf_language"
          name="language"
          error={!!(formik.errors.language && formik.touched.language)}
          errorMessage={formik.errors.language}
        />
        <ButtonContainer>
          <PrimaryButton loading={isLoading} type="submit">
            Submit
          </PrimaryButton>
        </ButtonContainer>
      </form>
      <Modal open={isModalOpen} />
    </Container>
  )
}

export {Form}

const Container = styled.section`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 816px;
  margin-top: -100px;
  box-shadow: 0 0 10px 2px rgba(20, 29, 41, 0.11);
  position: relative;
  z-index: 100;
  padding: 80px 100px;
  background-color: white;

  @media ${props => props.theme.BREAKPOINTS.MOBILE_DOWN} {
    padding: 32px 16px;
  }

  form {
    & > div {
    margin-bottom: 32px;
  }
`

const ButtonContainer = styled.section`
  padding-top: 24px;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`
